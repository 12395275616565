/* FOOTER */
.footer nav > ul > li {
  @apply mobile:min-w-fit min-w-[160px] md:min-w-fit;
}

nav.footer-column-onecol > ul {
  @apply flex flex-col columns-footer-one gap-x-10 gap-y-3;
}

nav.footer-column-twocol > ul {
  @apply grid mobile:grid-cols-1 grid-cols-2 columns-footer-two gap-x-10 gap-y-3;
}

.footer-copyright img {
  @apply inline-block;
}

.footer .footer-navigation {
  @apply flex-wrap justify-start gap-y-6 gap-x-10 lg:gap-x-8;
}
/* END FOOTER */

/* FOOTER GRADIENT */
.footer.bgcolor-gradient-exchange-red {
  @apply bg-[#711018] text-color-inverse;
}

.footer.bgcolor-gradient-exchange-red .copyright-logo {
  @apply hidden;
}

.footer.bgcolor-gradient-red {
  @apply bg-gradient-red text-color-inverse;
}

.footer.bgcolor-gradient-red.show-logo {
  @apply lg:bg-right lg:bg-no-repeat lg:bg-bgcolor-gradient-red-show-logo bg-right-bottom;
}

.footer.bgcolor-gradient-red .copyright-logo {
  @apply hidden;
}
/* END FOOTER GRADIENT */

/* FOOTER INK */
.footer.footer--ink {
  @apply bg-strokes-default-1 text-color-inverse;
}

.footer.footer--ink .copyright-logo {
  @apply hidden;
}
/* END FOOTER INK */

/* FOOTER LIGHT MODE */
.footer.light-mode {
  @apply bg-color-inverse text-color border-t border-strokes-default-3;
}

.footer.light-mode .copyright-logo {
  @apply visible;
}

.footer.light-mode.show-logo {
  @apply lg:bg-right lg:bg-no-repeat lg:bg-light-mode-show-logo;
}
/* END FOOTER LIGHT MODE */

/* JOURNALISM FOOTER */
.footer.light-mode-journalism.show-logo {
  @apply lg:bg-contain lg:bg-[right_-110px_top] lg:bg-no-repeat lg:bg-light-mode-journalism-show-logo;
}

.footer.light-mode-journalism .footer-navigation {
  @apply flex-wrap sm:flex-nowrap justify-start mobile:gap-y-3 gap-y-6 md:gap-y-0 gap-x-10 lg:gap-x-8;
}

.footer.light-mode-journalism .footer-navigation nav ul li {
  @apply min-w-fit;
}

.footer nav:not(.subscribe) ul li a {
  @apply font-normal text-color-default-1 hover:text-color-default-1;
}
.footer.footer--ink nav:not(.subscribe) ul li a {
  @apply text-color-inverse;
}
.footer.bgcolor-gradient-exchange-red ul li a {
  @apply !text-color-inverse;
}

.footer nav.subscribe ul li a {
  @apply text-sm no-underline;
}

.footer.light-mode-journalism.show-logo .copyright-logo {
  @apply hidden;
}
/* END JOURNALISM FOOTER */
