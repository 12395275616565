:root {
  /* Colors */
  --white: #fff;
  --burgendy: #731017;
  --burgendy-secondary: #450a0e;
  --medium-red: #c8102e;
  --medium-red-secondary: #780a1c;
  --vibrant-red: #e4002b;
  --vibrant-red-secondary: #89001a;
  --orange: #ff7f41;
  --orange-secondary: #e4002b;
  --warm-orange: #fc4c02;
  --warm-orange-secondary: #fd9467;
  --plum: #61293e;
  --plum-secondary: #815465;
  --slate-blue: #2b5a77;
  --slate-blue-secondary: #1a3647;
  --teal: #009bab;
  --teal-secondary: #005d66;
  --mint: #aff0c2;
  --mint-secondary: #8cc09b;
  --green: #249e6b;
  --green-secondary: #165f40;
  --oatmeal: #e9dcd3;
  --oatmeal-secondaey: #e9dcd3;

  /*gradient*/
  --burgendy-gradient-red-line: url('/marketing-promos/SLMPromo-lined-medred/SLMPromo-lined-D-beacon-medred.svg'),
    linear-gradient(90.86deg, var(--burgendy-secondary) 12.9%, var(--burgendy) 90.03%);
  --burgendy-gradient-red-line-m: url('/marketing-promos/SLMPromo-lined-medred/SLMPromo-lined-M-beacon-medred.svg'),
    linear-gradient(90.86deg, var(--burgendy-secondary) 12.9%, var(--burgendy) 90.03%);
  --burgendy-gradient-red-line-t: url('/marketing-promos/SLMPromo-lined-medred/SLMPromo-lined-T-beacon-medred.svg'),
    linear-gradient(90.86deg, var(--burgendy-secondary) 12.9%, var(--burgendy) 90.03%);

  --medium-red-gradient: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg'),
    linear-gradient(90.86deg, var(--medium-red-secondary) 12.9%, var(--medium-red) 90.03%);
  --medium-red-gradient-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg'),
    linear-gradient(90.86deg, var(--medium-red-secondary) 12.9%, var(--medium-red) 90.03%);
  --medium-red-gradient-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg'),
    linear-gradient(90.86deg, var(--medium-red-secondary) 12.9%, var(--medium-red) 90.03%);

  --vibrant-red-gradient: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg'),
    linear-gradient(90.86deg, var(--vibrant-red-secondary) 12.9%, var(--vibrant-red) 90.03%);
  --vibrant-red-gradient-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg'),
    linear-gradient(90.86deg, var(--vibrant-red-secondary) 12.9%, var(--vibrant-red) 90.03%);
  --vibrant-red-gradient-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg'),
    linear-gradient(90.86deg, var(--vibrant-red-secondary) 12.9%, var(--vibrant-red) 90.03%);

  --orange-gradient: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg'),
    linear-gradient(90.86deg, var(--orange-secondary) 12.9%, var(--orange) 90.03%);
  --orange-gradient-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg'),
    linear-gradient(90.86deg, var(--orange-secondary) 12.9%, var(--orange) 90.03%);
  --orange-gradient-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg'),
    linear-gradient(90.86deg, var(--orange-secondary) 12.9%, var(--orange) 90.03%);

  --warm-orange-gradient: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg'),
    linear-gradient(90.86deg, var(--warm-orange) 12.9%, var(--warm-orange-secondary) 90.03%);
  --warm-orange-gradient-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg'),
    linear-gradient(90.86deg, var(--warm-orange) 12.9%, var(--warm-orange-secondary) 90.03%);
  --warm-orange-gradient-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg'),
    linear-gradient(90.86deg, var(--warm-orange) 12.9%, var(--warm-orange-secondary) 90.03%);

  --plum-gradient: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg'),
    linear-gradient(90.86deg, var(--plum) 12.9%, var(--plum-secondary) 90.03%);
  --plum-gradient-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg'),
    linear-gradient(90.86deg, var(--plum) 12.9%, var(--plum-secondary) 90.03%);
  --plum-gradient-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg'),
    linear-gradient(90.86deg, var(--plum) 12.9%, var(--plum-secondary) 90.03%);

  --slate-blue-gradient: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg'),
    linear-gradient(90.86deg, var(--slate-blue-secondary) 12.9%, var(--slate-blue) 90.03%);
  --slate-blue-gradient-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg'),
    linear-gradient(90.86deg, var(--slate-blue-secondary) 12.9%, var(--slate-blue) 90.03%);
  --slate-blue-gradient-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg'),
    linear-gradient(90.86deg, var(--slate-blue-secondary) 12.9%, var(--slate-blue) 90.03%);

  --teal-gradient: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg'),
    linear-gradient(90.86deg, var(--teal-secondary) 12.9%, var(--teal) 90.03%);
  --teal-gradient-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg'),
    linear-gradient(90.86deg, var(--teal-secondary) 12.9%, var(--teal) 90.03%);
  --teal-gradient-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg'),
    linear-gradient(90.86deg, var(--teal-secondary) 12.9%, var(--teal) 90.03%);

  --mint-gradient: url('/marketing-promos/SLMPromo-lined-ink/SLMPromo-lined-D-beacon-ink.svg'),
    linear-gradient(90.86deg, var(--mint-secondary) 12.9%, var(--mint) 90.03%);
  --mint-gradient-m: url('/marketing-promos/SLMPromo-lined-ink/SLMPromo-lined-M-beacon-ink.svg'),
    linear-gradient(90.86deg, var(--mint-secondary) 12.9%, var(--mint) 90.03%);
  --mint-gradient-t: url('/marketing-promos/SLMPromo-lined-ink/SLMPromo-lined-T-beacon-ink.svg'),
    linear-gradient(90.86deg, var(--mint-secondary) 12.9%, var(--mint) 90.03%);

  --green-gradient: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg'),
    linear-gradient(90.86deg, var(--green-secondary) 12.9%, var(--green) 90.03%);
  --green-gradient-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg'),
    linear-gradient(90.86deg, var(--green-secondary) 12.9%, var(--green) 90.03%);
  --green-gradient-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg'),
    linear-gradient(90.86deg, var(--green-secondary) 12.9%, var(--green) 90.03%);

  --oatmeal-gradient: url('/marketing-promos/SLMPromo-lined-ink/SLMPromo-lined-D-beacon-ink.svg'),
    linear-gradient(90.86deg, var(--oatmeal) 12.9%, var(--oatmeal-secondaey) 90.03% 100%);
  --oatmeal-gradient-m: url('/marketing-promos/SLMPromo-lined-ink/SLMPromo-lined-M-beacon-ink.svg'),
    linear-gradient(90.86deg, var(--oatmeal) 12.9%, var(--oatmeal-secondaey) 90.03% 100%);
  --oatmeal-gradient-t: url('/marketing-promos/SLMPromo-lined-ink/SLMPromo-lined-T-beacon-ink.svg'),
    linear-gradient(90.86deg, var(--oatmeal) 12.9%, var(--oatmeal-secondaey) 90.03% 100%);

  --burgundy-gradient-white-line: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg'),
    linear-gradient(90.86deg, var(--burgendy-secondary) 12.9%, var(--burgendy) 90.03%);
  --burgundy-gradient-white-line-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg'),
    linear-gradient(90.86deg, var(--burgendy-secondary) 12.9%, var(--burgendy) 90.03%);
  --burgundy-gradient-white-line-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg'),
    linear-gradient(90.86deg, var(--burgendy-secondary) 12.9%, var(--burgendy) 90.03%);

  /* Only BG Images */
  --single-line-promo--burgundy: url('/marketing-promos/SLMPromo-lined-medred/SLMPromo-lined-D-beacon-medred.svg');
  --single-line-promo--burgundy-m: url('/marketing-promos/SLMPromo-lined-medred/SLMPromo-lined-M-beacon-medred.svg');
  --single-line-promo--burgundy-t: url('/marketing-promos/SLMPromo-lined-medred/SLMPromo-lined-T-beacon-medred.svg');

  --line-promo-multiple: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-D-beacon-white.svg');
  --line-promo-multiple-m: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-M-beacon-white.svg');
  --line-promo-multiple-t: url('/marketing-promos/SLMPromo-lined-white/SLMPromo-lined-T-beacon-white.svg');

  --single-line-promo--white: url('/marketing-promos/SLMPromo-lined-bokred/SLMPromo-lined-D-beacon-bokred.svg');
  --single-line-promo--white-m: url('/marketing-promos/SLMPromo-lined-bokred/SLMPromo-lined-M-beacon-bokred.svg');
  --single-line-promo--white-t: url('/marketing-promos/SLMPromo-lined-bokred/SLMPromo-lined-T-beacon-bokred.svg');

  --single-line-promo--double: url('/marketing-promos/SLMPromo-solid/SLMPromo-solid-D-beacon.svg'),
    url('/marketing-promos/SLMPromo-solid/SLMPromo-solid-D-border.svg');
  --single-line-promo--double-m: url('/marketing-promos/SLMPromo-solid/SLMPromo-solid-M-beacon.svg'),
    url('/marketing-promos/SLMPromo-solid/SLMPromo-solid-D-border.svg');
  --single-line-promo--double-t: url('/marketing-promos/SLMPromo-solid/SLMPromo-solid-T-beacon.svg'),
    url('/marketing-promos/SLMPromo-solid/SLMPromo-solid-D-border.svg');

  --single-line-promo--mint-oatmeal: url('/marketing-promos/SLMPromo-lined-ink/SLMPromo-lined-D-beacon-ink.svg');
  --single-line-promo--mint-oatmeal-m: url('/marketing-promos/SLMPromo-lined-ink/SLMPromo-lined-M-beacon-ink.svg');
  --single-line-promo--mint-oatmeal-t: url('/marketing-promos/SLMPromo-lined-ink/SLMPromo-lined-T-beacon-ink.svg');
}
