
/* BEGIN Login experience */

/* Block native browser input experience for custom styles */
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

input::-ms-reveal,
input::-ms-clear {
  @apply hidden;
}

.bokf-login .bokf-login-username {
  grid-area: username;
}

.bokf-login .bokf-login-password {
  grid-area: password;
}

.bokf-login .bokf-login-rememberme {
  grid-area: rememberme;
}

.bokf-login .bokf-login-forgotpassword {
  grid-area: forgotpassword;
}

.bokf-login {
  grid-template-areas:
    'username'
    'rememberme'
    'password'
    'forgotpassword';
}

@media (min-width: 768px) {
  .bokf-login {
    grid-template-areas:
      'username password'
      'rememberme forgotpassword';
  }
}

.bokf-checked {
  @apply bg-color-active bg-no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='10px' viewBox='0 0 14 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M13.538334,1.62856 L6.69333398,10.91756 L6.69333403,10.9175599 C6.35725903,11.3634519 5.72335003,11.4524769 5.27745403,11.1164019 C5.27271418,11.1128295 5.26800587,11.1092153 5.26332963,11.1055598 L0.375329628,7.19755984 L0.375329582,7.19755981 C-0.0560044178,6.85238181 -0.125848418,6.22289381 0.219329582,5.79155981 C0.564507582,5.36022581 1.19399558,5.29038181 1.62532958,5.63555981 C1.62532958,5.63555981 1.6253296,5.63555982 1.6253296,5.63555982 L5.7013296,8.89655982 L11.9283296,0.445559819 L11.9283295,0.445559897 C12.2345975,-0.0140251026 12.8554435,-0.138314103 13.3150295,0.167953897 C13.7746145,0.474221897 13.8989035,1.0950679 13.5926355,1.5546539 C13.5756736,1.580107 13.5575528,1.6047684 13.5383303,1.6285604 L13.538334,1.62856 Z' id='Path' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

input.bokf-search:focus,
input.bokf-search:focus + button.bok-clear-input {
  @apply border-icon-action outline-none;
}

/* END Login experience */


