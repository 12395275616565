/* BEGIN Rich Text Editor Styles */

.rtaw p:not(:last-child) {
  @apply mb-4;
}

.rtaw ul {
  @apply list-disc pl-10 mt-4 text-left;
}

.rtaw ul > li {
  @apply pb-4;
}

.rtaw ol {
  @apply list-decimal pl-10 mb-4;
}

.rtaw.alert-notice a {
  @apply text-color-inverse underline hover:cursor-pointer;
}

.rtaw table,
.rtaw table th,
.rtaw table td {
  border-width: 1px;
  border-color: #352b2b;
}

img.img-responsive {
  @apply max-w-full h-auto !important;
  display: unset;
}

.red-heading {
  @apply text-h2-title-sm md:text-h2-title-md lg:text-h2-title-lg block text-color-brand font-normal;
}

.hide-mobile {
  @apply hidden md:block;
}

.hide-tablet {
  @apply md:hidden lg:block;
}

.hide-mobile-tablet {
  @apply hidden lg:block;
}

.aspect-ratio--wide {
  @apply max-w-full relative;
}

.aspect-ratio--wide:before {
  @apply float-left pt-[56.25%] content-[''];
}

.aspect-ratio--wide:after {
  @apply block clear-both content-[''];
}

.aspect-ratio--wide > iframe,
.aspect-ratio--wide > img {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}

span.aspect-ratio--wide {
  @apply block;
}

.s1,
.s1 > p {
  @apply text-subtitle-1-regular-sm md:text-subtitle-1-regular-md lg:text-subtitle-1-regular-lg;
}

.s2,
.s2 > p {
  @apply text-subtitle-2-regular-sm md:text-subtitle-2-regular-md lg:text-subtitle-2-regular-lg;
}

/* END Rich Text Editor Styles */
