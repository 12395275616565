/* START Carousel Marquee Fixes For LHN */
main > #dl-content.sub-lhn-expanded .carousel-marquee,
main > #dl-content.sub-lhn-collapsed .carousel-marquee {
  @apply md:-ml-10 md:w-[calc(100%+2.5rem)];
}

@media (min-width: 1929px) {
  main > #dl-content.sub-lhn-collapsed .carousel-marquee {
    @apply ml-auto w-full;
  }

  main > #dl-content.sub-lhn-collapsed:has(.carousel-marquee) {
    @apply pl-0;
  }
}

@media (min-width: 2156px) {
  main > #dl-content.sub-lhn-expanded .carousel-marquee {
    @apply ml-auto w-full;
  }

  main > #dl-content.sub-lhn-expanded:has(.carousel-marquee) {
    @apply md:pl-0;
  }
}
/* END Carousel Marquee Fixes For LHN */

/* CAROUSEL */
.carousel-marquee,
.profiles-carousel-marquee {
  @apply w-full m-auto;
  --slide-height: 19rem;
  --slide-spacing: 0rem;
  --slide-size: 100%;
}

.carousel-marquee-viewport,
.profiles-carousel-marquee-viewport {
  @apply relative overflow-hidden m-auto;
}

.carousel-marquee-container,
.profiles-carousel-marquee-container {
  @apply flex;
  backface-visibility: hidden;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.carousel-marquee-slide,
.profiles-carousel-marquee-slide {
  @apply min-w-0 relative;
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
}

/* START Carousel Marquee Controls */
.carousel-marquee-controls {
  @apply flex justify-end lg:justify-center w-full bg-white;
}

.previous-next-buttons {
  @apply flex items-center;
}

.previous-button-container {
  @apply absolute inset-y-0 left-0 flex items-center m-auto;
}

.next-button-container {
  @apply absolute inset-y-0 right-0 flex items-center m-auto;
}

.previous-button {
  @apply w-11 h-[70px] md:w-13 md:h-[86px] lg:w-13 lg:h-[150px] flex justify-center items-center bg-[#f2f2f2] rounded-r-[4px];
}

.next-button {
  @apply w-11 h-[70px] md:w-13 md:h-[86px] lg:w-13 lg:h-[150px] flex justify-center items-center  bg-[#f2f2f2] rounded-l-[4px];
}

.carousel-marquee-dots_profile {
  @apply flex flex-nowrap h-[16px] justify-center items-center gap-[6px];
}

.carousel-marquee-dots {
  @apply flex flex-wrap h-[44px] justify-end items-center gap-[24px] mr-[16px] lg:mr-0 md:gap-4;
}

.carousel-marquee-dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  @apply bg-[#8c8c8c] rounded-full justify-center items-center flex h-[16px] w-[16px] m-0 p-0 border-0 cursor-pointer no-underline inline-block touch-manipulation;
}

.carousel-marquee-dot--selected {
  @apply bg-[#352b2b];
}

.carousel-marquee-play {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  color: var(--text-body);

  @apply p-0 no-underline touch-manipulation bg-transparent text-[0.875rem] font-normal justify-self-end justify-center items-center flex rounded-[1.8rem] m-0 border-0 cursor-pointer;
}

.carousel-toggle {
  @apply relative inline-block w-[46px] h-[25px] mt-[5px] mb-[5px];
}

.carousel-toggle input {
  @apply opacity-0 w-0 h-0;
}

/* END Carousel Marquee Controls */

/* START Carousel Marquee Slider */
.carousel-slider {
  @apply rounded-[6px] bg-[#cccccc] inset-0 cursor-pointer absolute duration-[0.4s];
}

.carousel-slider:before {
  @apply rounded-[5px] duration-[0.4s] bg-white bottom-[2px] left-[2px] w-[21px] h-[21px] absolute content-[''];
}

input:checked + .carousel-slider {
  background-color: #3964bf;
}

input:focus + .carousel-slider {
  box-shadow: 0 0 1px #3964bf;
}

input:checked + .carousel-slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}
/* END Carousel Marquee Slider */

/* START Carousel Marquee Login */

@media (max-width: 1199px) {
  .carousel-marquee-login {
    @apply hidden;
  }
}

@media (min-width: 1200px) {
  .carousel-marquee-login {
    @apply absolute right-10 top-13;
  }
}

@media (min-width: 1280px) {
  .carousel-marquee-login {
    @apply absolute left-1/2 top-13;
  }
}

/* END Carousel Marquee Login */

/* END CAROUSEL */
