/* Product Comparison Chart Classes */
:root {
  --slide-size: 25%;
}

.comparison-chart-wrapper .toggle-btn:focus,
.comparison-chart-wrapper .toggle-btn:hover {
  @apply bg-background-dark-1 rounded-sm;
}

.comparison-chart-wrapper .toggle-wrapper {
  @apply block leading-none;
}

.comparison-chart-wrapper .toggle-wrapper i,
.comparison-chart-wrapper .toggle-wrapper em {
  @apply hidden;
}

.comparison-chart-wrapper .toggle-btn::after {
  @apply content-[''] inline-block w-4 h-4 bg-[url('/icon-carat-down-blue.svg')] bg-contain bg-no-repeat align-middle transition-transform duration-300 ease-in-out ml-0.5 text-color-active;
}

.comparison-chart-wrapper .toggle-wrapper svg {
  @apply text-color-active;
}

.comparison-chart-wrapper .toggle-wrapper input[type='checkbox']:checked + .toggle-btn::after {
  @apply rotate-180;
}

.comparison-chart-wrapper .toggle-btn {
  @apply p-2 text-paragraph-3-regular-lg m-auto w-auto;
}

.comparison-chart-wrapper .toggle-content {
  @apply text-caption-lg;
}

.comparison-chart-wrapper .toggle-wrapper label i {
  @apply hidden;
}

.comparison-chart-wrapper .toggle-wrapper label input {
  @apply absolute h-0 w-0 overflow-hidden;
}

.comparison-chart-wrapper .toggle-wrapper label input ~ .toggle-content {
  @apply max-h-0 opacity-0 overflow-hidden transition-all duration-200 ease-in-out;
}

.comparison-chart-wrapper .toggle-wrapper label input:checked ~ .toggle-content {
  @apply mt-2 max-h-full opacity-100;
}

.comparison-chart-wrapper .toggle-wrapper label input:checked ~ * i svg.icon {
  @apply transform rotate-180 transition-transform duration-200;
}

.comparison-chart-wrapper .color-action-blue {
  @apply text-color-active;
}
/* Product Comparison Chart Classes END */
