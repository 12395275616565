/* BOK SCROLLBAR */
.bok-scrollbar {
    @apply border-r-8 border-color-inverse border-solid border-t-0 border-l-0 border-b-0;
    scrollbar-width: thin;
    scrollbar-color: #545454 #ffffff;
  }
  
  .bok-scrollbar::-webkit-scrollbar {
    @apply w-1;
  }
  
  .bokf-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-color-default-2 rounded-lg;
  }
  
  .bok-scrollbar--transparent {
    border-right-color: transparent !important;
    scrollbar-color: #545454 transparent;
  }
  
  .bok-scrollbar--no-border {
    @apply border-r-0;
  }
  
  /* END BOK SCROLLBAR */