/* BUTTONS */
.btn,
.rtaw .btn,
.rtaw .btn-prime,
.rtaw .btn-second {
  @apply inline-flex justify-center items-center leading-6 whitespace-nowrap py-2 px-10 w-full md:w-fit min-h-11 lg:min-h-12 focus:outline-none no-underline hover:no-underline;
}

.btn-primary,
.rtaw .btn-primary,
.rtaw .btn-prime {
  @apply text-color-inverse bg-btn-gradient rounded-full active:bg-btn-gradient-active focus:bg-btn-gradient-focus hover:bg-btn-gradient-hover hover:text-color-inverse;
}

.btn-secondary,
.rtaw .btn-secondary,
.rtaw .btn-second {
  @apply text-color-default-1 bg-button-secondary-background rounded-full border-2 border-solid border-button-secondary-enabled hover:border-button-secondary-hover active:border-button-secondary-enabled focus:border-button-secondary-focus hover:text-color-default-1;
}

.btn-auxiliary,
.rtaw .btn-auxiliary {
  @apply text-color-active rounded border-2 border-solid border-transparent bg-btn-auxiliary-gradient hover:border-button-auxiliary-hover hover:bg-background-default-2 active:border-button-auxiliary-active focus:border-button-auxiliary-focus focus:text-button-auxiliary-focus;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.btn-action,
.rtaw .btn-action {
  @apply text-color-active p-0 h-auto hover:underline;
}

.btn-inline,
.rtaw .btn-inline {
  @apply bg-clip-padding border-2 border-solid border-transparent rounded-[0.3125rem] text-color-active pb-1 text-[0.75rem] font-medium;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(-63.4349488229deg, #235ec9, #2a78e9) border-box;
}

.btn-inline--action {
  @apply text-color-active py-2 px-10 leading-[1.25rem];
}

/* END BUTTONS */
