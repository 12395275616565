/* BEGIN Snippets */
@tailwind base;

.snippet-quote-flex {
  @apply flex items-center justify-center;
}

.snippet-inline-image {
  @apply text-center pb-6 pr-6 float-left w-1/4;
}

.snippet-inline-image-right {
  @apply text-center pb-6 ml-6 mb-0 float-right w-1/4;
}

.snippet-video-embed-center {
  @apply mx-auto;
}

.snippet-video-embed-50 {
  @apply mb-2 w-1/2;
}

.snippet-video-embed-75 {
  @apply mb-2 w-3/4;
}

.snippet-video-embed-full {
  @apply mb-2 w-full;
}

.video-embed iframe,
.snippet-video-embed-50 iframe,
.snippet-video-embed-75 iframe,
.snippet-video-embed-full iframe {
  @apply w-full h-full aspect-video;
}

.snippet-center-1 {
  @apply text-center pb-6;
}

.snippet-center-ml {
  @apply text-center ml-0 md:ml-2;
}

.snippet-center-mr {
  @apply text-center mr-0 md:mr-2;
}

.snippet-quote-1 {
  @apply leading-7 md:leading-8 sm:text-base md:text-xl italic px-8 pb-2;
}

.snippet-quote-2 {
  @apply leading-7 md:leading-8 sm:text-base md:text-xl italic px-8;
}

.snippet-mp-1 {
  @apply mb-2 py-6;
}

.snippet-mp-2 {
  @apply mb-2;
}

.snippet-caption-video {
  @apply text-caption-sm md:text-caption-md lg:text-caption-lg w-9/12 mx-auto;
}

.snippet-caption-right {
  @apply text-right px-8;
}

.snippet-caption-center {
  @apply text-caption-sm md:text-caption-md lg:text-caption-lg text-center;
}

.snippet-quote-flex img,
.snippet-inline-image-right img {
  @apply !h-auto;
}

.snippet-quote-flex figure {
  @apply mr-0 md:mr-2 shrink-0;
}

.snippet-toggle-label {
  @apply mb-0;
}

.snippet-toggle-content .toggle-content {
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  transition: all 0.25s ease;
}

.snippet-toggle-label input[type='checkbox']:checked ~ .snippet-toggle-content .toggle-content {
  opacity: 1;
  max-height: 100vh;
  margin-top: 0.5rem;
}

.snippet-toggle-btn {
  @apply flex flex-row flex-wrap justify-center items-center gap-x-2 px-2 py-1 text-paragraph-3-regular-lg text-color-active cursor-pointer;
}

.snippet-toggle-btn:hover,
.snippet-toggle-btn:focus {
  @apply bg-background-dark-1 rounded;
}

.snippet-toggle-down {
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M1.09373 5.71992C0.862918 5.75878 0.659494 5.95277 0.599054 6.19166C0.58267 6.2564 0.58471 6.42997 0.602614 6.49566C0.62247 6.56849 0.663414 6.65062 0.710278 6.71166C0.773958 6.79459 9.19209 15.2049 9.26763 15.2611C9.49195 15.4278 9.72273 15.5046 9.99963 15.5046C10.2752 15.5046 10.5098 15.4266 10.7316 15.2612C10.8073 15.2048 19.2257 6.79403 19.289 6.71166C19.3658 6.61165 19.4044 6.50677 19.4127 6.37566C19.4257 6.16978 19.3423 5.98008 19.1814 5.84976C19.0712 5.76051 18.9669 5.72092 18.8234 5.71395C18.6749 5.70673 18.5633 5.73603 18.4436 5.81366C18.3997 5.84217 17.4849 6.75244 14.1936 10.0428L9.99963 14.2356L5.80563 10.0428C2.51434 6.75244 1.59957 5.84217 1.55563 5.81366C1.48438 5.76745 1.41983 5.74047 1.34195 5.72439C1.27765 5.7111 1.15878 5.70897 1.09373 5.71992Z" fill="%233964bf"/%3E%3C/svg%3E');
  background-size: cover;
  background-repeat: no-repeat;
}
.snippet-toggle-up {
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M9.92804 4.88409C9.67904 4.90463 9.47047 4.98129 9.27301 5.12487C9.18668 5.18764 0.726587 13.6484 0.683691 13.7149C0.563051 13.9018 0.553363 14.1458 0.658587 14.3471C0.706339 14.4385 0.826547 14.5587 0.916499 14.6051C1.01943 14.6582 1.08933 14.6746 1.21204 14.6746C1.3552 14.6746 1.44808 14.6475 1.56004 14.573C1.57984 14.5599 3.48694 12.6586 5.79804 10.3481L10 6.14715L14.202 10.3481C16.5131 12.6586 18.4202 14.5599 18.44 14.573C18.552 14.6475 18.6449 14.6746 18.788 14.6746C18.9108 14.6746 18.9807 14.6582 19.0836 14.6051C19.1735 14.5587 19.2937 14.4385 19.3415 14.3471C19.4467 14.1458 19.437 13.9018 19.3164 13.7149C19.2743 13.6497 10.8236 5.19789 10.732 5.12942C10.5675 5.00637 10.366 4.92179 10.176 4.89595C10.1034 4.88606 9.97728 4.88004 9.92804 4.88409Z" fill="%233964bf"/%3E%3C/svg%3E');
  background-size: cover;
  background-repeat: no-repeat;
}

/* END Snippets */

/* Start Skip to content*/
.skip-link {
  @apply items-center hidden md:flex bg-background-default-1 text-color-active text-[0.875rem] h-[2rem] justify-start left-[-1000px] mt-[-32px] p-[0.5rem] sticky underline top-[-1000px] w-full z-[9999];
}

a.skip-link:active,
a.skip-link:focus,
a.skip-link:hover {
  @apply left-0 mt-0 top-0;
}

/* End Skip to content*/

.snippet-table table {
  @apply m-0 p-0 w-full table-fixed border-collapse border-0 sm:border border-solid border-strokes-default-1;
}

.snippet-table table thead {
  @apply sr-only sm:not-sr-only;
}

.snippet-table table th {
  @apply p-4 border border-solid border-strokes-default-1 text-center;
}

.snippet-table table td {
  @apply flex sm:table-cell justify-between items-center text-right sm:text-left border-b-0 sm:border sm:border-solid sm:border-strokes-default-1 p-4 before:content-[attr(data-label)] sm:before:content-none before:float-left before:text-left before:font-bold before:uppercase before:pr-4 last:border last:border-strokes-default-1;
}

.snippet-table table tr {
  @apply block sm:table-row mb-2 sm:mb-0;
}
