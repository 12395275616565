

/* smartbanner App Banner CSS */
.smartbanner {
    @apply overflow-x-hidden w-full h-[84px] z-[2000] fixed left-0 top-0;
    @apply shadow-[0px_1px_0px_0px_rgba(0,0,0,0.05),0px_0.5px_0px_0px_rgba(0,0,0,0.15)];
  }
  .smartbanner__button {
    background: var(
      --Android-Light-mode-Button-Enabled,
      linear-gradient(91deg, #2a78e9 12.9%, #2364c9 90.03%)
    );
  }
  /* smartbanner App Banner CSS END */
  