.gm-style-iw-chr {
    @apply hidden;
}

.gm-style .gm-style-iw-c {
    @apply !p-2 bg-background-default-2 shadow-small
}

.gm-style-iw-d {
    @apply !p-0 !overflow-hidden;
}

.gm-style-iw-d a:focus-visible {
    @apply outline-none;
}

.gm-style-iw-d:focus-visible {
    @apply outline-none;
}