@tailwind base;
@tailwind components;
@tailwind utilities;

@import './product-comparison.css';
@import './rte.css';
@import './scrollbar.css';
@import './carousel-marquee.css';
@import './login.css';
@import './footer.css';
@import './smart-banner.css';
@import './snippet.css';
@import './button.css';
@import './calcXML.css';
@import './map.css';

:root {
  --alert-notice-height: 0px; /* Default value */
}
@layer base {
  /* BEGIN Base html elements */
  body {
    @apply bg-background-default-1;
  }

  a {
    @apply text-color-active font-medium sm:word-break-break-word hover:text-color-link-hover hover:underline focus:underline;
  }

  hr {
    @apply my-4 stroke-strokes-default-3;
  }

  /* END Base html elements */

  /* FONTS */
  h1,
  .headline-1 {
    @apply text-h1-title-sm md:text-h1-title-md lg:text-h1-title-lg;
  }

  h2,
  .headline-2 {
    @apply text-h2-title-sm md:text-h2-title-md lg:text-h2-title-lg;
  }

  h3,
  .headline-3 {
    @apply text-h3-title-sm md:text-h3-title-md lg:text-h3-title-lg;
  }

  h4,
  .headline-4 {
    @apply text-h4-title-sm md:text-h4-title-md lg:text-h4-title-lg;
  }

  h5,
  .headline-5 {
    @apply text-h5-title-sm md:text-h5-title-md lg:text-h5-title-lg;
  }

  .subtitle-1-regular {
    @apply text-subtitle-1-regular-sm md:text-subtitle-1-regular-md lg:text-subtitle-1-regular-lg;
  }

  .subtitle-1-medium {
    @apply text-subtitle-1-medium-sm md:text-subtitle-1-medium-md lg:text-subtitle-1-medium-lg;
  }

  .subtitle-2-regular {
    @apply text-subtitle-2-regular-sm md:text-subtitle-2-regular-md lg:text-subtitle-2-regular-lg;
  }

  .subtitle-2-medium {
    @apply text-subtitle-2-medium-sm md:text-subtitle-2-medium-md lg:text-subtitle-2-medium-lg;
  }

  .paragraph-1-regular {
    @apply text-paragraph-1-regular-sm md:text-paragraph-1-regular-md lg:text-paragraph-1-regular-lg;
  }

  .paragraph-1-regular-underline {
    @apply text-paragraph-1-regular-sm md:text-paragraph-1-regular-md lg:text-paragraph-1-regular-lg underline;
  }

  .paragraph-1-medium {
    @apply text-paragraph-1-medium-sm md:text-paragraph-1-medium-md lg:text-paragraph-1-medium-lg;
  }

  .paragraph-1-medium-underline {
    @apply text-paragraph-1-medium-sm md:text-paragraph-1-medium-md lg:text-paragraph-1-medium-lg underline;
  }

  .paragraph-2-regular {
    @apply text-paragraph-2-regular-sm md:text-paragraph-2-regular-md lg:text-paragraph-2-regular-lg;
  }

  .paragraph-2-regular-underline {
    @apply text-paragraph-2-regular-sm md:text-paragraph-2-regular-md lg:text-paragraph-2-regular-lg underline;
  }

  .paragraph-2-medium {
    @apply text-paragraph-2-medium-sm md:text-paragraph-2-medium-md lg:text-paragraph-2-medium-lg;
  }

  .paragraph-2-medium-underline {
    @apply text-paragraph-2-medium-sm md:text-paragraph-2-medium-md lg:text-paragraph-2-medium-lg underline;
  }

  .paragraph-3-regular {
    @apply text-paragraph-3-regular-sm md:text-paragraph-3-regular-md lg:text-paragraph-3-regular-lg;
  }

  .paragraph-3-regular-underline {
    @apply text-paragraph-3-regular-sm md:text-paragraph-3-regular-md lg:text-paragraph-3-regular-lg underline;
  }

  .paragraph-3-medium {
    @apply text-paragraph-3-medium-sm md:text-paragraph-3-medium-md lg:text-paragraph-3-medium-lg;
  }

  .paragraph-3-medium-underline {
    @apply text-paragraph-3-medium-sm md:text-paragraph-3-medium-md lg:text-paragraph-3-medium-lg underline;
  }

  .caption {
    @apply text-caption-sm md:text-caption-md lg:text-caption-lg;
  }

  .caption-underline {
    @apply text-caption-sm md:text-caption-md lg:text-caption-lg underline;
  }

  .caption-medium {
    @apply !text-caption-medium-sm md:!text-caption-medium-md lg:!text-caption-medium-lg;
  }

  .caption-medium-underline {
    @apply text-caption-medium-sm md:text-caption-medium-md lg:text-caption-medium-lg underline;
  }
  .quote {
    @apply leading-7 md:leading-8 sm:text-base md:text-xl;
  }
  /* END FONTS */

  /* COMPONENT STYLES */
  .info-cards {
    @apply max-w-[48rem] m-auto;
    --slide-height: 19rem;
    --slide-spacing: 1.6rem;
    --slide-size: 100%;
  }

  .info-cards-controls {
    @apply flex justify-center gap-[1.2rem] mt-[1rem];
  }

  .border-default,
  .cta-collection .border-default {
    @apply border border-[#e4e4e4];
  }

  .cta-collection .bg-white {
    @apply bg-background-default-2;
  }

  .cta-collection .padding-bkgd {
    @apply p-0;
  }

  .object--m--contain,
  .object--t--contain,
  .object--contain {
    @apply object-contain;
  }

  /* END COMPONENT STYLES */

  .left-nav img {
    @apply invisible opacity-0;
    transition: all 0.5s ease;
  }

  .left-nav .show {
    @apply visible opacity-100;
  }

  .flex-center-33 {
    @apply flex grow-0 basis-full md:basis-1/3;
  }

  .accordion-collection {
    height: 0;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  .accordion-collection.showText {
    height: auto;
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: 1px) and (max-width: 767px) {
    .flex-center-25 .info-cards__item {
      flex: 0 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 960px) {
    .flex-center-25 .info-cards__item {
      flex: 0 calc(100% * (1 / 2) - 8px);
    }
  }
  @media (min-width: 960px) {
    .flex-center-25 .info-cards__item {
      flex: 0 calc(100% * (1 / 4) - 12px);
    }
  }
  @media (min-width: 1px) and (max-width: 767px) {
    .flex-center-33 .info-cards__item {
      flex: 0 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 960px) {
    .flex-center-33 .info-cards__item {
      flex: 0 calc(100% * (1 / 2) - 8px);
    }
  }
  @media (min-width: 960px) {
    .flex-center-33 .info-cards__item {
      flex: 0 calc(100% * (1 / 3) - 11px);
    }
  }
  @media (min-width: 1px) and (max-width: 767px) {
    .flex-center-50 .info-cards__item {
      flex: 0 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 960px) {
    .flex-center-50 .info-cards__item {
      flex: 0 calc(100% * (1 / 2) - 8px);
    }
  }
  @media (min-width: 960px) {
    .flex-center-50 .info-cards__item {
      flex: 0 calc(100% * (1 / 2) - 8px);
    }
  }

  .hero .imgBleed {
    @apply justify-center md:justify-start items-center text-center relative basis-full -my-8 md:basis-1/2 -mb-2;
  }

  .hero .imgFluidWidth {
    @apply lg:max-w-[1024px] lg:my-0 lg:mx-auto;
  }

  /* END COMPONENT STYLES */
}

@layer utilities {
  .tw-hidden {
    @apply hidden;
  }
}

.gm-ui-hover-effect {
  @apply !hidden;
}

.spacing-lg {
  @apply mt-14 md:mt-16 lg:mt-24;
}

.spacing-md {
  @apply mt-12 md:mt-14 lg:mt-16;
}

.spacing-sm {
  @apply mt-4 md:mt-6 lg:mt-8;
}

/* CONTAINER */
.container,
.container-wide > .container-inner {
  @apply w-full lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1536px] mx-auto px-4;
}

.container-fixed {
  @apply lg:max-w-[994px] xl:max-w-[1250px] 2xl:max-w-[1506px] mx-auto;
}

.container-wide {
  @apply lg:max-w-[1334px] xl:max-w-[1590px] 2xl:max-w-[1836px] mx-auto;
}

/* END CONTAINER */
.padding-bkgd {
  @apply p-4 md:p-8 lg:p-12;
}
/* TO DO - Jay R & Chris Y - Can you please move your CSS into its respective files? */
/* Fixes For Header Navigation */
[aria-label='page-title'],
[aria-label='breadcrumbs'] {
  @apply mt-12 md:mt-16;
}

[aria-label='breadcrumbs'] + [aria-label='page-title'] {
  @apply mt-0;
}

/* END Fixs For Header Navigation */

/* Header Animation */
.header-hidden {
  @apply -top-36 transition-all duration-200 ease-in-out;
}

.header-show {
  @apply top-0 transition-all duration-200 ease-in-out;
}
/* END Header Animation */

/* BEGIN Title Border Rendering Selectors */
.title-border-burgundy {
  @apply bg-background-burgundy;
}

.title-border-deep-teal {
  @apply bg-background-deep-teal;
}

.title-border-green {
  @apply bg-background-green;
}

.title-border-ink {
  @apply bg-background-ink;
}

.title-border-medium-red {
  @apply bg-background-medium-red;
}

.title-border-mint {
  @apply bg-background-mint;
}

.title-border-natural {
  @apply bg-background-natural;
}

.title-border-oatmeal {
  @apply bg-background-oatmeal;
}

.title-border-orange {
  @apply bg-background-orange;
}

.title-border-plum {
  @apply bg-background-plum;
}

.title-border-teal {
  @apply bg-background-teal;
}

.title-border-vibrant-red {
  @apply bg-background-vibrant-red;
}

.title-border-violet {
  @apply bg-background-violet;
}

.title-border-orange {
  @apply bg-background-orange;
}

.title-border-white {
  @apply bg-background-default-2;
}
/* END Title Border Rendering Selectors */

/* BEGIN Marketo Form Overrides */

form.mktoForm .mktoFormRow .mktoFormCol .mktoFieldWrap .mktoHtmlText.mktoHasWidth {
  width: 100% !important;
}

form.mktoForm .mktoFormRow .mktoFormCol {
  padding-left: 0 !important;
}

.marketo-form-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

@media (min-width: 768px) {
  .marketo-form-container {
    max-width: 100%;
  }
}
/* END Marketo Form Overrides */

/* BEGIN Additional Login Page Custom Zebra Styling */

section#logins-split li:nth-child(odd) {
  background-color: #f6f4f3;
}

section#logins-split li {
  padding: 0.5rem;
  margin: 0;
}

/* END Additional Login Page Custom Zebra Styling */
